@charset "utf-8";

// COLORS
$theme-colors: (
  "primary": #0074d9,
  "secondary": #6c757d,
  "success": #28a745,
  "danger": #ff4136,
  "warning": #ffc107,
  "info": #17a2b8,
  "light": #f8f9fa,
  "dark": #343a40,
  "white": #ffffff,
  "transparent": transparent
);

// TEXT
$font-family-sans-serif: "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-serif: Georgia, "Times New Roman", Times, serif;
$font-family-base: $font-family-sans-serif;
$font-size-base: 1rem;

// BREAKPOINTS
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);
@import url(https://fonts.googleapis.com/css?family=Roboto:100);
.wrapper {
  //position: relative;
  background-color: steelblue;

  height: 100%;
  //height: 100vh;


  width: 100%;
  //width: 100vh;
}


$c1 : #674172;
$c2 : #ffffff;

.aa_particles
{
  z-index: 4;

  height: 100%;
  height: 100vh;


 // width: 100%;
 // width: 100vh;

  background: steelblue;
}
.aa_inner
{
  padding: 0 1rem;

  text-transform: uppercase;
  text-align: center;
  background: $c2;
}
.aa_txt
{
  font: 100 9vh/1 Roboto;

  text-align: center;

  color: steelblue;
}

.aa_sec
{
  position: absolute;
  z-index: 5;
  top: 50%;
  right: 0;
  left: 0;

  margin: 0  auto;
}

.aa_of
{
  padding: 1rem;

  color: $c2;
  background: darken($c2,5%);
}

.text{
 // background-color: black;
  font: 100 10vh/1 Roboto;
  color: steelblue;
  position: relative;
  text-align: center;
  top: 40%;
  width: 100%;
}

// SEE DOCS FOR MORE:
// https://bit.ly/2sgFMdb

// IMPORT BOOTSTRAP
@import "~bootstrap/scss/bootstrap";

// IMPORT FONT AWESOME
@import url("https://use.fontawesome.com/releases/v5.10.1/css/all.css");
